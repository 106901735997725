.container {
  @include container;
}

.w-small,
.w-medium,
.w-large,
.w-larger,
.w-xl,
.w-xs {
  margin-bottom: gutter();
  margin-top: gutter();

  @include susy-breakpoint(1024px, $susy, '.no-mq') {
    margin-bottom: gutter()-5;
    margin-top: gutter()-5;
  }
}

.w-small {
  @include span(4);
  @include susy-breakpoint(1024px, $susy, '.no-mq') {
    @include span(12);
  }

}

.w-xs {
  @include span(3);
  @include susy-breakpoint(1024px, $susy, '.no-mq') {
    @include span(12);
  }
  
}
.w-xxs {
  @include span(2);
  @include susy-breakpoint(1024px, $susy, '.no-mq') {
    @include span(12);
  }
}
.flex-equalheight{
  clear: both;
  display: flex;
  justify-content: space-between;
}

.w-medium {
 // width: 50%
  @include span(6);
  @include susy-breakpoint(1024px, $susy, '.no-mq') {
    @include span(12);
  }
}

.w-large {
  @include span(8);
  @include susy-breakpoint(1024px, $susy, '.no-mq') {
    @include span(12);
  }
}


.w-larger {
  @include span(9);
  @include susy-breakpoint(1024px, $susy, '.no-mq') {
    @include span(12);
  }
}

.w-xl {
  @include span(12);
  &.no-lr-padding {
    padding-left: 0;
    padding-right: 0;
  }

  &.no--margin {
    margin-bottom: 0;
    @include susy-breakpoint(1024px, $susy, '.no-mq') {
      margin-bottom: 5px;
    }
  }
}


.actions {
  i {
      margin-right: 10px;
      cursor: pointer;
      font-size: 14px;
      @include transition();

      &:hover {
          font-weight: 600;
          color: $primary;
      }
  }
}

.source-grid {
  border: 1.5px solid #ebebeb;
  clear: both;
}



.tabs-badge {
  position: absolute;
  right: 5px;
  // left: 100px;
  top: 10px;
}

.code-snippet-label {
  padding-top: 10px;
  padding-bottom: 10px;
}

.code-snippet-copy {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 20px;
  cursor: pointer;

  &:hover {
      background: #d5d5d5;
      border-radius: 33px;
  }
}

.character-limit {
  margin-top: 10px;
  font-size: 12px;
}

.file-note {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  border: 1px solid $neutral-300;
  padding: 5px;

  h2 {
      font-weight: 500;
      color: $neutral-800;
  }

  p {
      color: $neutral-500;
  }
}