.browser-frame {
    position: relative;
    width: 450px;
    margin: 20px auto;
    overflow: hidden;
    background: #d1d5db;
    box-shadow: 0px 29px 33px rgba(0, 0, 0, 0.2), 0px 0px 0px #000000;
    border-radius: 9px;

    .browser-header {
        background-color: #212121;
        height: 50px;
        padding-top: 10%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 16px;
    }

    .browser-buttons {
        display: flex;
        margin-right: auto;

        .browser-button {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 8px;

            &.red {
                background-color: #ff5f57;
            }

            &.yellow {
                background-color: #ffbd2e;
            }

            &.green {
                background-color: #28c940;
            }
        }
    }

    .browser-content {
        padding: 20px;
        position: relative;
        // display: flex;
        align-items: center;
        justify-content: center;
    }
}