//form styles

.form-container {
  @include clearfix();

  .input-search {
    width: 100%;
  }

  .form-error {
    margin-top: 3px;
    color: red;
    display: block;
  }

  .field-validation-error {
    margin-top: 3px;
    color: red;
    display: block;
  }
}

.form-column {
  margin-bottom: gutter();
  // margin-top: gutter();
}

.one.form-column,
.one.form-column {
  @include span(1);
}

.two.form-column {
  @include span(2);
}

.three.form-column {
  @include span(3);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.four.form-column {
  @include span(4);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.five.form-column {
  @include span(5);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.six.form-column {
  @include span(6);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.seven.form-column {
  @include span(7);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.eight.form-column {
  @include span(8);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.nine.form-column {
  @include span(9);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.ten.form-column {
  @include span(10);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.eleven.form-column {
  @include span(11);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.twelve.form-column {
  @include span(12);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.one-third.form-column {
  @include span(4);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.two-thirds.form-column {
  @include span(8);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.one-half.form-column {
  @include span(6);

  @include susy-breakpoint(1024px, $susy, ".no-mq") {
    @include span(12);
  }
}

.no-label.form-column {
  padding-top: 19px;
  // line-height: 32px;
}

.form-label {
  display: block;
  font-size: 12px;
  font-weight: 500;
  padding: 0 0 5px 0;

  .required {
    color: red;
  }
}

.character-limit {
  font-size: 11px;
  color: #999;
  padding: 3px 0 0 0;
}




.form-field {
  position: relative;

  .p-password {
    display: block;
  }

  input[type="text"],
  input[type="url"],
  input[type="email"],
  input[type="number"],
  input[type="tel"],
  input[type="password"] {
    width: 100%;


    &:disabled {
      background: #f1f1f1;
      opacity: 0.5;
    }

    &.field--error {

      input[type="text"],
      input[type="url"],
      input[type="email"],
      input[type="password"] {
        border-color: red;
      }
    }

    &.btn--field {
      .btn {
        margin-right: 5px;
      }
    }
  }

  &.field--error {

    input[type="text"],
    input[type="url"],
    input[type="email"],
    input[type="password"] {
      border-color: red;
    }
  }

  textarea {
    width: 100%;
    padding: 6px;
    border: 1px solid #ced4da;
    font-family: $primary-font-regular;
    font-size: 12px;
    border-radius: 5px;
    height: 102px;

    &:focus {
      border-color: $primary;
    }
  }

  &.field--search {
    &:before {
      // font-family: "Font Awesome\ 5 Pro";
      font-family: "Font Awesome 6 Pro";
      content: "\f002";
      font-weight: 400;
      position: absolute;
      right: 14px;
      top: 11px;
    }
  }
}

.field-message {
  background: #f9f9f9;
  border-radius: 3px;
  padding: 10px;

  span {
    &:first-child {
      font-weight: bold;
    }
  }
}




.form-row {
  margin: 0 -10px;
  clear: both;
}

.form-row-two {
  margin: 0;
  clear: both;
}



.drawer-user {
  padding: 0 10px;

  h2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }
}

.two-factor-authentication {
  font-size: 14px;
  accent-color: $primary;

  input {
    position: relative;
    top: 2px;
    margin: 0 5px 0 0;
    padding: 0;
  }
}

.PhoneInputInput {
  width: 100%;
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #d0d7dd;
  font-family: $primary-font-regular;
  font-size: 12px;
  color: #666;

  &:focus {
    border-color: $primary;
    outline: none;
  }

  &:disabled {
    background: #f1f1f1;
    opacity: 0.5;
  }

}


.text-right {
  text-align: right;
}