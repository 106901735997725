.toggle {
    padding: 0 0 12px;

    ul {
        display: flex;
        justify-content: space-between;

        li {
            label {
                font-size: 11px;
                margin: 0;
                font-weight: 400;
            }
        }
    }

    input {
        position: absolute;
        left: -9999px;
    }

    .toggle__switch {
        position: relative;
        display: block;
        width: 34px;
        height: 14px;
        cursor: pointer;
        border-radius: 20px;
        transition: 350ms;
        background: $neutral-300;
        margin-left: auto;

        &::after {
            position: absolute;
            content: '';
            width: 20px;
            height: 20px;
            top: -4px;
            left: 0;
            border-radius: 50%;
            transition: 250ms ease-in-out;
            background: #e5e7eb;
            fill: var(--neutral-200, #E5E7EB);
            filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.12));

        }
    }

    input:checked+.toggle__switch {


        &::after {
            left: 15px;
            background: $primary;
        }
    }

    input:disabled+.toggle__switch {
        opacity: 0.3;
        cursor: not-allowed;
    }
}

.toggle-list {
    display: flex;
    padding: 8px 0;

    .toggle {
        margin: 0 5px;
        padding: 0;
    }
}

.tooltip-icon {
    font-size: 14px;
    margin-left: 5px;
    color: #c3c3c3;
    cursor: pointer;

    &:hover {
        color: $primary;

        i {
            font-weight: 600;
        }
    }
}

// .bot-source-controller {
//     display: flex;
//     justify-content: center;

//     ul {
//         display: flex;
//         gap: 10px;
//         list-style: none;
//         padding: 0;
//         margin: 0;
//         flex-wrap: wrap;
//     }

//     li {
//         display: flex;
//         align-items: center;
//         gap: 5px;
//     }
// }

.toggle {
    padding: 0;
}

.bot--setting {
    margin-top: 5px;
    margin-left: 0 !important;
}