.login {
  width: 100%;
  padding: 0;
  background: $white;
  height: 100%;
  background: #F3F4F6 url(../images/bg.jpg) no-repeat;
  background-size: cover;

  @include respondTo($lg) {
    padding: 20px;
  }

}

.login-form {
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  .form {

    width: 300px;

    &.register {
      width: 500px;
    }

    .login-logo {
      margin-bottom: 20px;

      img {
        max-width: 120px;
      }

    }

    &__header {
      padding: 0 0 20px;
      text-align: center;

      .logo-login {
        text-align: left;
        display: none;
        margin-bottom: 20px;

        img {
          max-width: 220px;
        }

      }

      h2 {
        font-size: 24px;
        line-height: 1.2;
        margin: 15px 0 5px 0;
      }

      p {
        font-size: 16px;
        font-weight: 300;
        color: #667085;
      }
    }

    .form__group {
      padding: 0 0 20px;
      position: relative;

      //      @include transition();
      &.login--actions {
        padding-bottom: 0;
      }
    }

    .form__label {
      font-size: 13px;
      font-weight: 300;
      font-family: 'Gilroy';
      margin-bottom: 5px;
      color: #344054;
      display: block;
    }

    .form__input {
      font-family: 'Gilroy';
      font-weight: 500;
      width: 100%;
      max-width: 100%;
      font-size: 14px;
      color: #666;
      padding: 9px 14px 9px 14px;
      // border: 0;
      //  border: 1px solid $field-border;
      background: $white;
      transition: all 0.3s;
      line-height: 1.2;
      border-radius: 5px;
      background: #E6E7E9;

      &:disabled {
        opacity: 0.5;
        background: #fbfbfb;
      }

      //  @include placeholder-color(#a6b1b9);
      &:focus {
        border-color: $primary;
        outline: none;
        box-shadow: none;
        background: white;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
      }
    }

    .validation-message {
      // color: $clr-error;
      font-size: 12px;
    }

    .field__error {

      // color: $clr-error;
      font-size: 12px;
      margin-top: 3px;

      font-weight: 300;

      p {
        padding: 5px 0;
      }
    }

    .actions {
      font-size: 13px;

      .form-link {
        float: right;
        padding: 10px 0 0 0;
      }

      .btn {
        float: left;
      }
    }
  }
}


.login--actions {
  text-align: right;
}


.alert-message {
  // color: $clr-error;
  margin-top: 10px;
  background: #F4F4F4;
  border: 1px solid #E6E6E6;
  display: inline-block;

  p {
    padding: 5px;
    font-size: 13px !important;
  }

  ul {
    list-style: none;
  }

  li {
    padding: 5px;
    font-size: 13px !important;
  }

}

.success-alert-message {
  //color: $clr-error;
  margin-top: 10px;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  display: inline-block;

  p {
    padding: 5px;
    font-size: 13px !important;
  }

  ul {
    list-style: none;
  }

  li {
    padding: 5px;
    font-size: 13px !important;
  }

}


.login-btn {
  display: block;
  padding: 12px 14px;
  width: 100%;
  border-radius: 5px;
  // background: $primary;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 1.2;
  transition: all 0.3s ease-in-out;
  font-weight: 500;
  margin-bottom: 10px;

  &:hover {
    //  background-color: darken($primary, 10%);
    color: white;
    text-decoration: none;

  }
}





.error--field {
  .form__input {
    border-color: #DE7A7A !important;
  }

  .form__label {
    color: #DE7A7A;
  }

}

.form-grid-row {
  display: flex;
  gap: 10px;
  justify-content: space-between;

  .form__group {
    flex: 1;
  }
}


.two-factor-authentication {
  accent-color: $primary;

  input {
    position: relative;
    top: 2px;
    margin: 0 5px 0 0;
    padding: 0;
  }
}


.forgot-password {
  color: $primary;
  cursor: pointer;
  font-size: 14px;
  text-align: center;

  &:hover {
    text-decoration: underline;
    color: $secondary;
  }
}