.pull-top-20 {
    margin-top: -20px;
}

.pull-top-25 {
    margin-top: -25px;
}

.pull-top-35 {
    margin-top: -35px;
}

.push-top-12 {
    margin-bottom: 12px;
}

.pl-16 {
    padding-left: 16px;
}

.pr-16 {
    padding-right: 16px;
}

.pb-0 {
    padding-bottom: 0;
}

.pt-10 {
    padding-top: 10px;
}

.pt-30 {
    padding-top: 30px;
}

.pt-50 {
    padding-top: 50px;
}

.pb-30 {
    padding-bottom: 30px;
}

.pl-25 {
    padding-left: 25px;
}

.pr-25 {
    padding-right: 25px;
}

.mr-19 {
    margin-right: 19px;
}

.mt-15 {
    margin-top: 15px;
}

// .mt-20 {
//     margin-top: 20px;
// }

.ml-20 {
    margin-left: 20px;
}

.mr-20 {
    margin-right: 20px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}


.components-main-container {
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    h1 {
        text-align: center;
        margin-bottom: 30px;
        text-transform: uppercase;
        text-decoration: underline;
        font-size: 2.5rem;
    }
}