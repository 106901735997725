.comment-mic {
    cursor: pointer;
    @include transition(all 0.3s ease);
    position: absolute;
    right: auto;
    z-index: 1;
    top: 0;
    width: 26px;
    height: 26px;
    text-align: center;

    color: $primary;

    i {
        font-size: 18px;
        line-height: 26px !important;
    }



    &:hover {
        color: darken($primary, 10%);
        // transform: scale(1.1);
    }

    .cat__list & {
        left: 10px;
        top: 0;
    }

    .filter-search & {
        left: 45px;
        top: 2px;
    }

    .form-field & {
        position: static;
    }

    .login & {
        left: 35px;
    }

    .search-field & {
        left: 45px;
        top: 2px;
    }

    .model-search & {
        left: 55px;
        top: 18px;
    }

    .template__subject & {
        top: 6px;
        left: 10px;
    }

    .filter__trigger & {
        left: 35px;
        top: 10px;
    }

    .filter--hierarchy.active & {
        left: 65px;
        top: 12px;
        background: #fff;
    }

    .translation__form & {
        left: 15px;
        top: 40px;
    }

    i {
        &:before {
            display: inline-block;
            font-family: 'Font Awesome 6 Pro';
            content: '\f130';
            font-style: normal;
            position: relative;
            font-weight: 500;
        }
    }
}

.comment-mic.mic--active {
    color: $destructive-500;

    i {
        &:before {
            font-family: 'Font Awesome 6 Pro';
            content: '\f28d';
            font-weight: 600;
            position: relative;
            // top: -2px;
        }
    }
}

.animation-outer {
    width: 16px;
    height: 16px;
    display: none;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    position: absolute;
    margin: -8px 0 0 -8px;
    background: #d5d5d5;
    transition: 1.5s all ease;
    -webkit-animation: woong 1.5s infinite;
    -moz-animation: woong 1.5s infinite;
    -o-animation: woong 1.5s infinite;
    animation: woong 1.5s infinite;

    .mic--active & {
        color: $primary;
        display: block;
    }
}

@-o-keyframes woong {
    0% {
        -webkit-trasform: scale(1.2);
    }

    50% {
        -webkit-transform: scale(1.8);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(2.4);
        opacity: 0;
    }
}

@-webkit-keyframes woong {
    0% {
        -webkit-trasform: scale(1.2);
    }

    50% {
        -webkit-transform: scale(1.8);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(2.4);
        opacity: 0;
    }
}

@-moz-keyframes woong {
    0% {
        -webkit-trasform: scale(1.2);
    }

    50% {
        -webkit-transform: scale(1.8);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(2.4);
        opacity: 0;
    }
}

@keyframes woong {
    0% {
        -webkit-trasform: scale(1.2);
    }

    50% {
        -webkit-transform: scale(1.8);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(2.4);
        opacity: 0;
    }
}

.mic-loader span {
    display: block;
    margin-top: 10px;
}

.mic-loader span::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 15px;
    width: 15px;
    border: 2px solid $primary;
    border-bottom: 2px solid transparent;
    border-radius: 50%;
    -webkit-animation: loader-3-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-3-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

@-webkit-keyframes loader-3-1 {
    0% {
        -webkit-transform: rotate(0deg);
    }

    40% {
        -webkit-transform: rotate(180deg);
    }

    60% {
        -webkit-transform: rotate(180deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes loader-3-1 {
    0% {
        transform: rotate(0deg);
    }

    40% {
        transform: rotate(180deg);
    }

    60% {
        transform: rotate(180deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.mic-loader span::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 3px;
    height: 3px;
    background: $primary;
    border-radius: 50%;
    -webkit-animation: loader-3-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-3-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

@-webkit-keyframes loader-3-2 {
    0% {
        -webkit-transform: translate3d(0, -32px, 0) scale(0, 2);
        opacity: 0;
    }

    50% {
        -webkit-transform: translate3d(0, 0, 0) scale(1.25, 1.25);
        opacity: 1;
    }

    100% {
        -webkit-transform: translate3d(0, 8px, 0) scale(0, 0);
        opacity: 0;
    }
}

@keyframes loader-3-2 {
    0% {
        transform: translate3d(0, -32px, 0) scale(0, 2);
        opacity: 0;
    }

    50% {
        transform: translate3d(0, 0, 0) scale(1.25, 1.25);
        opacity: 1;
    }

    100% {
        transform: translate3d(0, 8px, 0) scale(0, 0);
        opacity: 0;
    }
}

.audioInputs {
    justify-content: end;
    min-width: 26px;
    height: 26px;

    .toggle {
        margin-right: auto;
    }

    .agent-button {
        position: relative;

        // .animation-outer {
        //     width: 20px;
        //     height: 20px;
        //     top: 0;
        //     right: 2px;
        // }
    }

    .agent-button.disable {
        pointer-events: none;
        border: 1px solid #bbbdbf;
        color: #bbbdbf;
    }
}