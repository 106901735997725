//loader overlay
.loader-overlay, .CustomerHistory-loader-overlay {
  background-color: $white;
  height: 100%;
  width: 100%;
  position: fixed;
  //@include opacity(0.9);
  opacity: 0.9;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($zindex, overlay);
  &.loader--widget {
    position: absolute;
    //z-index: map-get($zindex, overlaywidget);
  }
}

.loader-overlay-w {
  background-color: $white;
  height: 100%;
  width: 100%;
  position:absolute;
  //@include opacity(0.9);
  opacity: 0.9;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: map-get($zindex, overlay);

  &.loader--widget{
    position: absolute;
  }
}


.loader, .CustomerHistory-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 16px;
  margin: -8px 0 0 -20px;
}


.loader-static{
  padding:50px;
}


$size:22px;

// Loaders

.loader-spinner {
    width : $size;
    height: $size;
    border:3px solid $primary-100;
    border-bottom-color: $primary;
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
  }
  
  /* keyFrames */ 
  
  @keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
  }
  
