body {
    // overflow: auto !important;
}

.white-button {
    background: white;
}

// body{
//     -webkit-font-smoothing: antialiased;
// }
.page-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;

    h1 {
        font-size: 18px;
        font-weight: 500;
    }
}

.resend-link {
    color: $primary;
    @include transition();
    padding-top: 3px;
    text-decoration: underline;
    display: block;
}

.content {
    float: left;
    width: 100%;
    padding: 55px 0 0 50px;
}

.apps-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;

    .list__item {
        text-align: left;
        //flex: 1;
        flex: 1 0 20%;
        margin: 8px;
    }
}

.container {

    // max-width: 1300px;
    &.form-container {
        max-width: 800px;
        margin: 20px auto;
    }
}

.grid-container {
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
}

.app-content {
    float: left;
    width: 100%;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
    padding: 20px;

    &.nopadding {
        padding: 0;
    }

    .content__inner {
        padding: 20px;
    }

    h2 {
        float: left;
        font-size: 18px;
        padding: 0 0 15px;
        font-weight: 400;
        margin: 0;
    }

    .search-result {
        border: 1px solid #e9e9e9;
        padding: 20px;
        border-radius: 3px;
        background: #f5f5f5;

        li {
            list-style-type: none;
            display: revert;
            color: #676a6e;
            font-size: 12px;
            margin-bottom: 5px;

            .btn {
                background: #fff;
                color: #313a46;
                padding: 8px 15px;
                font-weight: 500;
                border-radius: 20px;
                cursor: pointer;
                border: 1px solid $primary;

                &:hover,
                &:active {
                    color: $white;
                    background-color: $primary;
                }
            }
        }
    }
}

.grey-container-box {
    float: left;
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    position: relative;
    position: relative;
    background: $neutral-50;
    // border: 1px solid $neutral-200;
    border-radius: 8px;

    label {
        font-weight: 400;
        color: $neutral-700;
    }
}

.grey-container-box-200 {
    @extend.grey-container-box;
    background: $neutral-50;
    margin-left: 15px;
    padding-left: 25px;
}

.slot-form-container {
    float: left;
    width: 100%;
    padding: 15px;
    padding-top: 0px;
    margin-bottom: 10px;
    position: relative;
    position: relative;
    background: $neutral-100;
    border-radius: 10px;
}

.form-column twelve {
    border: 1px solid $neutral-100;
}

.grey-container-box-bot {
    // background-color: $primary-100;
    background: white;
    padding: 10px 15px 10px 15px;
    height: fit-content;
    width: fit-content;
    margin-top: 10px;
    margin-left: 0px;
    margin-bottom: 10px;
    border: 1px solid #e5e7eb;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    align-items: center;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 80%;
}

.grey-container-box-error {
    background: #fce1e1;
    padding: 10px 30px 40px 15px;
    margin-bottom: 10px;
    color: #c73131;
    position: relative;
    border-radius: 10px;
    height: 40px;
}

.vertical-separator {
    height: 80vh;
    background-color: #f9f8f9;
    border: 1px solid #d0d7dd;
    margin: 2em 0;
}

.horizontal-separator {
    margin: 10px;
    border-bottom: 1px solid $neutral-300;
}

// .chat {
//     float: left;
//     width: 100%;
//     border: 1px solid #e9e9e9;
//     padding: 15px 20px;
//     background: #f5f5f5;
//     border-radius: 5px;

//     li {
//         margin-bottom: 10px;
//         position: relative;

//         p {
//             display: inline-block;
//             background: white;
//             padding: 7px 20px;
//             font-size: 12px;
//             border-radius: 25px;
//         }

//         .btn--undo {
//             position: absolute;
//             border-radius: 100%;
//             width: 25px;
//             height: 25px;
//             line-height: 34px;
//             padding: 0;
//             // border: 2px solid #f9f8f9;
//             //left: -30px;
//             //left: 0;
//             top: 50%;
//             margin-top: -17px;
//             font-size: 14px;
//             text-align: center;

//             &:hover {
//                 color: red;
//             }
//         }

//         .btn--skip {
//             position: absolute;
//             border-radius: 100%;
//             width: 25px;
//             height: 25px;
//             line-height: 34px;
//             padding: 0;
//             font-size: 14px;
//             text-align: center;
//             // border: 2px solid #a5a6a9;
//             //  right: -25px;
//             top: 50%;
//             margin-top: -17px;

//             &:hover {
//                 color: red;
//             }
//         }

//         &.user {
//             text-align: right;

//             p {
//                 background: $primary;
//                 color: white;
//             }
//         }
//     }
// }

// .top-dropdown{
//   float:right;
// }

.field-dropdown {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .dropdown__container {
        flex: 1;
    }
}

.chat-window-static {
    .rcw-widget-container {
        position: static;
        // height: 90vh;
        height: calc(100vh - 60px);
    }
}

.preview-chat-window-static {
    .rcw-widget-container {
        position: static;
        height: 400px;
    }
}

.flex {
    display: flex;
}

.space-between {
    justify-content: space-between;
}

.status {
    padding: 0 10px 0 20px;
    line-height: 20px;
    border-radius: 50px;
    color: $neutral-800;
    display: inline-block;
    font-weight: 500;
    background: $neutral-100;
    font-size: 12px;
    position: relative;

    &:before {
        position: absolute;
        left: 8px;
        top: 6px;
        border-radius: 100%;
        content: '';
        width: 8px;
        height: 8px;
        background: $neutral-500;
    }

    &.hasImage,
    &.created {
        background: $primary-100;

        &:before {
            background: $primary-500;
        }
    }

    &.inprogress,
    &.unsubscribed,
    &.sent,
    &.training,
    &.email_sent {
        background: $warning-100;

        &:before {
            background: $warning-500;
        }
    }

    &.hasNoImage {
        background: $neutral-100;

        &:before {
            background: $neutral-500;
        }
    }

    &.expired,
    &.failed,
    &.error,
    &.pending,
    &.detractor,
    &.undelivered {
        background: $destructive-100;

        &:before {
            background: $destructive-500;
        }
    }

    &.completed,
    &.delivered,
    &.survey_opened,
    &.email_delivered,
    &.email_read,
    &.trained,
    &.promotor {
        background: $success-100;

        &:before {
            background: $success-500;
        }
    }
}

.filters {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .item--action {
        .action__button {
            margin-right: 5px;
            display: inline-block;

            &:last-child {
                margin: 0;
            }
        }
    }

    &.flex-start {
        justify-content: flex-start;
    }

    .filter__item {
        margin-left: 10px;
    }

    .filter__calendar {
        .p-calendar-w-btn-right .p-inputtext {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .p-calendar-w-btn-right .p-datepicker-trigger {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .p-inputtext {
            width: 178px;
        }
    }

    .search-field {
        .p-inputtext {
            width: 230px;
        }
    }

    .filter__calendar {
        position: relative;
        margin-left: 10px;

        .p-inputtext {
            width: 205px;
        }
    }

}

.tooltip-info-container {
    background-color: #e0e0e0;
    padding: 10px;
    width: 400px;
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.expanded-row-container {
    background-color: $white;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
    border-radius: 10px;
    padding: 10px 10px 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 220px;
    overflow-y: auto;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #e1e2e5;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #bdbebf;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b6b6b7;
    }
}

.resource-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid $neutral-100;

    &:last-child {
        border: 0;
    }

    .resource-label {
        color: $neutral-900;

        .resource-icon {
            font-family: 'Font Awesome 6 Pro';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 1;
            color: $neutral-400;
            margin-right: 10px;
        }
    }
}

.progress-text {
    color: #66686b;
    font-weight: 500;
    text-align: center;
    align-items: center;
    margin-left: 10px;
}

.error-label {
    // margin-top: 5px;
    color: $destructive-500;
    margin-top: 3px;

    // p {
    //     color: $destructive-500;
    //     margin-top: 3px;
    // }
}

.error-chat-message {
    color: $destructive-700;
    margin-top: 5px;

    p {
        color: $destructive-700;
        margin-top: 3px;
    }
}

.dialog-heading {
    font-weight: 500;
    margin-bottom: 10px;
}

.agent-message {
    background: white;
    padding: 10px 15px 10px 15px;
    height: fit-content;
    width: fit-content;
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    border: 1px solid #e5e7eb;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    max-width: 80%;
}

.icon-buttons-row {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
}

.icon-buttons-row {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
}

.bot-name {
    display: flex;
    align-items: center;
}

.user-name {
    font-weight: 600;
}

.user-initial {
    width: 28px;
    width: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 100%;
    color: $neutral-800;
    display: inline-block;
    font-weight: 500;
    background: $primary-100;
    color: $primary-500;
    font-size: 12px;
    position: relative;
    margin-right: 10px;

    &.user--0 {
        background: $success-100;
        color: $success-500;
    }

    &.user--1 {
        background: $destructive-100;
        color: $destructive-500;
    }

    &.user--2 {
        background: $warning-100;
        color: $warning-500;
    }

    &.user--3 {
        background: $neutral-100;
        color: $neutral-500;
    }

    &.user--4 {
        background: $primary-100;
        color: $primary-500;
    }

    &.user--5 {
        background: $success-100;
        color: $success-500;
    }
}

////
.user-name {
    font-weight: 600;
}

.user-initial {
    width: 28px;
    width: 28px;
    text-align: center;
    line-height: 28px;
    border-radius: 100%;
    color: $neutral-800;
    display: inline-block;
    font-weight: 500;
    background: $primary-100;
    color: $primary-500;
    font-size: 12px;
    position: relative;
    margin-right: 10px;

    &.user--0 {
        background: $success-100;
        color: $success-500;
    }

    &.user--1 {
        background: $destructive-100;
        color: $destructive-500;
    }

    &.user--2 {
        background: $warning-100;
        color: $warning-500;
    }

    &.user--3 {
        background: $neutral-100;
        color: $neutral-500;
    }

    &.user--4 {
        background: $primary-100;
        color: $primary-500;
    }

    &.user--5 {
        background: $success-100;
        color: $success-500;
    }
}

.context-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 12px 0px 0px 0px;
    padding: 12px 14px;
    gap: 8px;
    width: auto;
    height: auto;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 8px 8px 8px 8px;
    flex: none;
    order: 1;
    flex-grow: 0;

    .h2 {
        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #000000;
        flex: none;
        order: 1;
        flex-grow: 0;
    }

    .source-icon {
        margin-right: 5px;
    }
}

.response-provider-container {
    @extend .context-container;

    display: flex;
    flex-direction: row;
    gap: 20px;
    font-size: 14px;

    .label {
        color: $neutral-700;
    }

    .value {
        font-weight: 500;
        font-style: italic;
        color: $primary;
    }
}

.source-tag {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6px;
    isolation: isolate;
    width: auto;
    height: 20px;
    background: #e1e1fe;
    border-radius: 50px;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 5px;
    font-weight: 600;
}

.source {
    margin: 12px 0px 0px 0px;
    padding: 12px 14px;
    gap: 8px;
    border: 1px solid $neutral-200;
    border-radius: 10px;

    .source-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 7px 0;

        i {
            font-size: 14px;
            color: $neutral-400;
            margin-right: 5px;
        }
    }
}

.Chat-detail-header {
    width: 111px;
    height: 11px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 75.8%;
    margin: 12px 0px 12px 0px;
    text-transform: capitalize;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.agent-text {
    width: auto;

    height: auto;
    font-family: 'Gilroy';
    font-style: normal;

    font-weight: 500;

    font-size: 12px;
    line-height: 20px;
    color: $neutral-800;
    display: flex;
    order: 1;
    flex-grow: 0;
}

.agent-facing-bot-separator {
    position: absolute;
    width: 810px;
    height: 0px;
    left: 904px;
    top: 1px;
    border: 1px solid #e5e7eb;
    transform: rotate(90deg);
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #f1f1f1;
    color: #555;
    font-size: 14px;
    text-align: center;
}

.custom-file-upload:hover {
    background-color: #ddd;
}

.custom-file-upload i {
    margin-right: 5px;
}

input[type='file'] {
    display: none;
}

.converse-sidebar {
    display: flex;
    justify-content: space-between;
    height: 100vh;

    .col-left {
        width: 50%;
        height: 100vh;
        padding: 20px;
        overflow-y: auto;
    }

    .col-right {
        width: 50%;
        background: $neutral-50;
        border-left: 1px solid $neutral-300;
        height: 100vh;
        padding: 20px;
        overflow-y: auto;
    }
}

.converse--sidebar {
    .p-sidebar-content {
        padding: 0;
    }

    .p-sidebar-header {
        display: none;
    }
}

.converse-close {
    width: 24px;
    height: 24px;
    line-height: 24px;
    color: #313a46;
    border: 0;
}

.time-specification {
    // margin: 20px 0px 0px 0px;
    padding: 5px 0;
    gap: 8px;
    // border: 1px solid $neutral-200;
    // border-radius: 10px;

    .time-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin: 5px 0;

        i {
            font-size: 14px;
            color: $neutral-400;
            margin-right: 5px;
        }
    }
}

.selected-bot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .p-dropdown {
        flex-grow: 1;
        width: 15vw;
    }

    .p-button {
        width: 5vw;
    }
}

.accordion-icon {
    color: $primary;
    font-size: 16px;
}

.p-tabview .p-tabview-nav {
    background: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    font-size: 13px !important;
    font-weight: 500 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    outline: none !important;
}

.custom-nobg {
    .p-tabview-panels {
        background: none !important;
        padding: 10px 0 0 !important;
    }

    .p-tabview-nav li.p-highlight {
        border-bottom: none !important;
    }
}

/* Remove the underline from the active tab */

.flex-item-center {
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
        font-size: 14px;
        color: $neutral-400;
        margin-right: 5px;
    }

    p {
        font-size: 12px;
        color: $neutral-800;
        font-weight: 600;
    }
}

.bot-action-icon {
    ul {
        display: flex;
        gap: 10px;

        i {
            font-size: 16px;
            color: $neutral-400;
            @include transition();

            &:hover {
                font-weight: 600;
                color: $primary;
            }
        }
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.knowledge-icon {
    font-size: 18px;
    color: $neutral-400;
    margin-right: 8px;
}

.sort-icon-table {
    position: relative;
    width: 18px;
    height: 20px;
    display: block;
    font-style: initial;

    &:before {
        position: absolute;
        top: 8px;
        content: '\f107';
        font-size: 12px;
        color: $neutral-500;
        font-family: 'Font Awesome 6 Pro';
        left: 50%;
        margin-left: -5px;
    }

    &:after {
        position: absolute;
        content: '\f106';
        font-size: 12px;
        color: $neutral-300;
        font-family: 'Font Awesome 6 Pro';
        left: 50%;
        margin-left: -5px;
    }
}

.bot-source-containerr {
    position: relative;
    // width: 1251px;
    height: auto;
    // padding: 20px;
    // left: 121px;
    top: 10px;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
    border-radius: 10px;
}

.tooltip-content {
    width: 600px;
}

.tooltip-title {
    margin-left: 10px;
    margin-bottom: 10px;
}

.tooltip-list {
    list-style: none;
    padding: 0;
}

.tooltip-item {
    list-style: none;
    margin-bottom: 10px;
}

.tooltip-bullet {
    color: white;
    margin-right: 5px;
}

.expand-button {
    margin-top: 19px;

    button {
        margin-right: 5px;
    }
}

.chat-control-leftsidebar {
    position: fixed;
    top: 50px;
    left: 60px;
    width: 280px;
    height: 100%;
    background-color: white;
    border-right: 1px solid $neutral-200;
    padding: 14px;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #e1e2e5;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #bdbebf;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b6b6b7;
    }
}

.sessions {
    padding: 0;
}

.chat-control-widget {
    //width: 100%;
    padding: 0 375px 0 280px;

    .rcw-widget-container {
        position: static;
        height: 92vh;
    }

    .rcw-conversation-container {
        background: none;
        border: 0;
    }

    .rcw-sender {
        background: white;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    }
}

.source-container-bot {
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 15px 10px 15px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
    margin: 10px 0;

    &.error {
        background: $destructive-100;
        padding: 0px;
        box-shadow: none;
        border: 0px;
    }
}

.chat-actions-container-bot,
.survey-container-bot {
    background: white;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 15px 10px 15px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid $neutral-300;
    margin: 10px 0;
}

.sidebar-actions-top {
    display: flex;
    align-items: center;

    button {
        text-align: center;
        display: inline;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;

        i {
            margin-right: 5px;
            font-size: 14px;
        }
    }
}

.chat-control-search {
    padding: 10px 0;

    >span {
        display: block;
    }
}

.session-history {
    margin-top: 10px;
    overflow: auto;
    height: calc(100% - 90px);

    h3 {
        font-weight: 600;
        padding: 10px 0;
        color: $neutral-400;
    }

    .converstion {
        padding: 12px;
        white-space: nowrap;
        position: relative;
        border-radius: 6px;
        padding-left: 25px;

        >i {
            font-size: 14px;
            position: absolute;
            left: 10px;
            top: 15px;
        }

        .session-loader {
            position: absolute;
            right: 10px;
            top: 5px;
        }

        .delete {
            opacity: 0;
            visibility: hidden;
            transition: 250ms ease-in-out;
            position: absolute;
            right: 10px;
            top: 5px;
        }

        .edit-button {
            opacity: 0;
            visibility: hidden;
            transition: 250ms ease-in-out;
            position: absolute;
            right: 30px;
            top: 5px;
        }

        p {
            color: $neutral-500;
            font-weight: 600;
            margin-left: 10px;

            &.bot-name {
                font-weight: normal;
            }
        }

        &.active {
            background: $neutral-200;
        }

        &:hover {
            cursor: pointer;

            .delete {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .selected {
        background-color: #ccc;
    }
}

.p-dialog .p-dialog-footer {
    background: #fbfbfb;
    border-top: 1px solid #f2f2f2;
    padding: 1em;
}

.footer-left-sidebar {
    position: absolute;
    padding: 10px;
    bottom: 50px;
    width: 100%;
    left: 0;

    button {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }
}

.selected-prompt {
    background-color: $primary;
}

.p-button-label {
    font-weight: 500;
}

.source-container-bot {

    ol,
    ul {
        list-style-type: decimal;
        list-style-position: inside;

        p {
            display: inline-block;
        }

        li {
            display: list-item;
            line-height: 20px;
            margin: 2px 0;

            p {
                display: inline;
            }
        }

        ul {
            list-style-type: circle;
            list-style-position: inside;
            padding-left: 15px;
        }
    }
}

.rcw-conversation-container {

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #e1e2e5;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #bdbebf;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b6b6b7;
    }
}

.custom-css-scroll {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    max-height: none;
    margin-top: 10px;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #e1e2e5;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #bdbebf;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b6b6b7;
    }
}

.conversion-state {
    text-align: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -83px 0 0 -242px;

    img {
        margin-bottom: 20px;
    }

    p {
        color: $neutral-400;
        font-size: 14px;
    }
}

.color-palette {
    background: white;
    padding: 5px;
    cursor: pointer;
    height: 32px;
    border-radius: 5px;
    display: flex;
    max-width: 90px;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border: 1px solid $neutral-200;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    .colors__container {
        width: 20px;
        border: 1px solid #d0d7dd;
        height: 20px;
        border-radius: 100%;
    }
}

.theme-dropdown {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 36px;
}

.slider {
    margin: 10px 0;
    padding: 0 3px;
}

.slider-legends {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
}

.slider-input-field {
    margin-top: 15px;

    input {
        text-align: center;
        width: 100%;
    }
}

.file-upload {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;

    .p-image {
        border-radius: 100%;
        overflow: hidden;
        width: 32px;
        height: 32px;
        background: #f6f6f6;
        text-align: center;
    }
}

.chat-answer {
    float: left;
    width: 100%;
    margin-top: 10px;
}

.text-limit {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    font-size: 10px;
    color: $neutral-400;
}

.slider-labels {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .slider-value {
        border-radius: 10px;
        color: white;
        min-width: 20px;
        background: $primary;
        min-width: 30px;
        padding: 2px 5px;
        text-align: center;
    }
}

.p-slider-handle {
    width: 20px !important;
    height: 20px !important;
    margin-top: -10px !important;
}

.form-field-depth {
    width: 5px;
}

.chips-domain {
    background-color: $success-100;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    // background: $primary;
    // color: white;
    //  height: 30px;
}

.horizontal-elements {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 10%;
}

.allowed-external {
    display: flex;
    align-items: center;
    gap: 10px;
}

.without--label {
    height: 20px;
}

.form--footer {
    display: flex;
    align-items: center;
    justify-content: end;

    button {
        margin-left: 5px;
    }
}

.number--field {
    input {
        text-align: center;
    }
}

.p-chips-multiple-container {
    width: 100%;
    min-height: 50px;
}

.info-bulb-note {
    background: $warning-50;
    padding: 10px 15px;
    margin-top: 10px;
    border: 1px solid $warning-200;
    position: relative;
    display: flex;
    gap: 10px;
    border-radius: 5px;
    align-items: baseline;

    p {
        font-size: 12px;
        line-height: 20px;
    }

    .bulb {
        color: $warning-500;
        font-size: 12px;
    }
}

.p-chips-token {
    margin-bottom: 5px;
}

.outer-action-container {
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    gap: 10px;
    margin-top: 10px;

    .p-button {
        padding: 0.5rem 1rem;
    }
}

.outer-button {
    box-shadow: none;
    outline: none;

    i {
        font-size: 14px;
        transition: font-size 0.3s ease, font-weight 0.3s ease;

        &:hover {
            font-weight: 600;
        }
    }
}

.source-container-bot {
    position: relative;

    &.--buttons-inside {
        padding-top: 30px;

        .bot-message-actions {
            top: 1px;
            right: 5px;
            left: unset;
        }
    }

    .bot-message-actions {
        position: absolute;
        top: -30px;
        left: 0;
        display: flex;
        gap: -5px;
        align-items: center;
    }
}

.rcw-sender {
    &.--with-audio {
        gap: 10px;
    }
}

.dropdown--full,
.field--max,
.width--100 {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.progress-container {
    display: flex;
    align-items: center;

    .p-progressbar {
        width: 80px;
        height: 5px;
    }
}

.confirmation-content {
    line-height: 16px;
}

.file-format-chip {
    padding: 2px 7px 2px 7px;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    margin: 0 6px 8px 0;
    display: inline-block;
    background-color: $primary-100;
}

.doc-info-item {
    display: flex;
    margin-right: 20px;
}

.doc-info-label {
    display: inline-block;
}

.doc-info-value {
    display: inline-block;
}

.bot-grid-content {
    border-radius: 20px;
    padding-top: 20px;
}

.form-disabled {
    pointer-events: none;
    opacity: 0.6;
}

.draft-container {
    width: 33%;
    background: white;
    border-radius: 10px;
    margin: 5px;
    padding: 10px 15px 10px 15px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid $primary-200;
    cursor: pointer;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.highlight {
        background: $primary-50;
    }

    &.selected {
        background: $primary-100;
    }
}

.draft-title {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 5px;
    height: auto;
    width: auto;
    display: inline-block;
    padding: 5px;
    border-radius: 8px;
    background: $primary-100;

    &.highlight {
        background: $primary-200;
    }

    &.selected {
        background-color: $primary-500;
        color: $white;
    }
}

.draft-toggle {
    display: flex;
    font-weight: 600;
    padding-top: 15px;
    cursor: pointer;
    // color: $primary-300;

    i {
        padding-top: 2px;
        padding-left: 10px;
    }
}

.main-draft-container {
    display: flex;
    transition: all 0.6s ease-in-out;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
}

.main-draft-container.show {
    opacity: 1;
    max-height: 1000px;
    /* adjust this value as needed */
}

.source-context {
    overflow: hidden;
    transition: max-height 2s ease-out;

    .title {
        font-weight: 600;
        font-size: 12px;
        margin-bottom: 5px;
        height: auto;
        width: auto;
        display: inline-block;
        padding: 5px;
        border-radius: 8px;
        color: $neutral-700;
    }

    .content {
        font-size: 12px;
        color: $neutral-500;
        font-weight: 500;
        margin-bottom: 5px;
        width: auto;
        display: inline-block;
        padding: 5px;
    }
}

.source-context:hover {
    max-height: none;
}

.context-resource {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: $primary-500;
    padding-bottom: 6px;
    display: flex;
    align-items: center;

    i {
        margin-right: 5px;
    }

    p {
        display: inline-block;
    }
}

.draft-container {
    width: 33%;
    background: white;
    border-radius: 10px;
    margin: 5px;
    padding: 10px 15px 10px 15px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid $primary-200;
    cursor: pointer;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.highlight {
        background: $primary-50;
    }

    &.selected {
        background: $primary-100;
    }
}

.draft-title {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 5px;
    height: auto;
    width: auto;
    display: inline-block;
    padding: 5px;
    border-radius: 8px;
    background: $primary-100;

    &.highlight {
        background: $primary-200;
    }

    &.selected {
        background-color: $primary-500;
        color: $white;
    }
}

.draft-toggle {
    display: flex;
    font-weight: 600;
    padding-top: 15px;
    cursor: pointer;
    // color: $primary-300;

    i {
        padding-top: 2px;
        padding-left: 10px;
    }
}

.main-draft-container {
    display: flex;
    transition: all 0.6s ease-in-out;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
}

.main-draft-container.show {
    opacity: 1;
    max-height: 1000px;
    /* adjust this value as needed */
}

.paragraph-content {
    overflow: hidden;
    transition: max-height 2s ease-out;
    max-height: 100px;
}

.paragraph-content:hover {
    max-height: none;
}

.context-resource {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: $primary-500;
    padding-bottom: 6px;
    display: flex;
    align-items: center;

    i {
        margin-right: 5px;
    }

    p {
        display: inline-block;
    }
}

.draft-container {
    width: 33%;
    background: white;
    border-radius: 10px;
    margin: 5px;
    padding: 10px 15px 10px 15px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid $primary-200;
    cursor: pointer;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.highlight {
        background: $primary-50;
    }

    &.selected {
        background: $primary-100;
    }
}

.draft-title {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 5px;
    height: auto;
    width: auto;
    display: inline-block;
    padding: 5px;
    border-radius: 8px;
    background: $primary-100;

    &.highlight {
        background: $primary-200;
    }

    &.selected {
        background-color: $primary-500;
        color: $white;
    }
}

.draft-toggle {
    display: flex;
    font-weight: 600;
    padding-top: 15px;
    cursor: pointer;
    // color: $primary-300;

    i {
        padding-top: 2px;
        padding-left: 10px;
    }
}

.main-draft-container {
    display: flex;
    transition: all 0.6s ease-in-out;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
}

.main-draft-container.show {
    opacity: 1;
    max-height: 1000px;
    /* adjust this value as needed */
    max-height: 1000px;
    /* adjust this value as needed */
}

.vertical-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.logo-container {
    background: $primary-100;
    border-radius: 100%;
    height: 40px;
    width: 40px;
    padding: 5px;
    display: inline-block;
    margin-right: 10px;

    i {
        font-size: 14px;
        color: $primary-500;
        height: 40px;
        width: 40px;
    }
}

.logo-image-upload {
    position: relative;
    height: 32px;

    img {
        width: 32px;
        height: 32px;
    }

    .file-remove {
        position: absolute;
        top: 3px;
        z-index: 100;
        right: -5px;
        background: white;
        border-radius: 100%;
    }
}

.theme-customizer-toggle {
    display: flex;
    padding-top: 15px;
    cursor: pointer;

    i {
        padding-top: 2px;
        padding-left: 10px;
        color: $primary-400;
    }
}

.theme-customizer-container {
    // display: flex;
    transition: all 0.6s ease-in-out;
    opacity: 0;
    min-height: 0;
    overflow: hidden;
    margin: 10px 0px 0;
    opacity: 1;
    min-height: 100px;

    &.show {
        opacity: 1;
        max-height: 1000px;
    }

    .theme-element-container {
        width: 100%;
        height: 3%;
        padding: 7px 6px;
        border-radius: 3px;
        border: 1px solid $neutral-300;
        gap: 10px;
        background-color: $white;

        .theme-element {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            align-items: center;
            gap: 5px;
            align-content: center;
        }
    }

    .grey-container-box {
        border-radius: 0;
        border-top: 1px solid $neutral-200;
        border-bottom: 1px solid $neutral-200;
    }
}

.prebuild-theme-container {
    display: flex;
}

.prebuild-theme-color {
    display: flex;
    flex-direction: row;
    width: 35px;
    height: 35px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px;
    position: relative;
    margin-right: 5px;

    &:hover {
        box-shadow: 0 2px 8px $neutral-300;
    }

    &.active {
        // border: 1px solid $primary-700;
        // box-shadow: 0 2px 8px $neutral-300;

        .tick-icon {
            font-size: 16px;
            color: #ffffff;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
        }
    }
}

.tick-icon {
    display: none;
}

.bot-settings {
    clear: both;
    display: flex;
    border: 1px solid $neutral-200;
    border-radius: 5px;

    .controller-section {
        background: white;
        flex-grow: 1;
        flex-basis: 0;
        padding: 15px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .preivew-section {
        flex-grow: 1;
        flex-basis: 0;
        border-left: 1px solid $neutral-200;
        padding: 15px;
        background: $neutral-50;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}

.source-info {
    display: flex;
    flex-direction: column;
}

.source-name {
    grid-column: span 2;
    padding-top: 4px;
    color: $primary;
    font-weight: 500;
}

.tab-panel-icon {
    font-size: 12px;
    color: $clr-red;
    margin-left: 5px;
}

.tab-header-icon {
    font-size: 12px;
    color: $primary;
    margin-left: 5px;

    &.error {
        color: $clr-red;
    }
}

.slot-form-dropdown,
.p-dropdown-item {
    white-space: normal !important;
    word-wrap: break-word !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    min-width: 100% !important;
    max-width: 250px !important;
}

.slot-form-dropdown-tooltip {
    white-space: normal !important;
    word-wrap: break-word !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    // min-width: 100% !important;
    max-width: 250px !important;
}

.no-scroll {
    overflow: hidden !important;
}

.multi-select {
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.action-with-toggle {
    display: flex;
    align-items: center;
    gap: 10px;

    i {
        // margin-right: 10px;
        cursor: pointer;
        font-size: 14px;
        @include transition();

        &:hover {
            font-weight: 600;
            color: $primary;
        }
    }
}

.centered-heading {
    justify-content: center;
    text-align: center;
}

.bot-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h2 {
        font-size: 14px;
        font-weight: 600;
    }
}

.bot-source-controller {
    margin-top: 10px;

    .toggle__switch {
        margin-top: 10px;
    }

    .toggle-list {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        border-bottom: 1px solid #e5e0e0;
        margin-bottom: 15px;
        padding-bottom: 15px;
    }

    li {
        display: flex;
        align-items: center;
        gap: 5px;
        margin-right: 10px;
    }
}

.toggle-list {
    li {
        display: flex;
        margin: 0 10px 0 0;

        .toggle__switch {
            padding-right: 5px;
            padding-left: 0;
            margin-left: 0;
        }
    }
}

.chat-body-header {
    align-items: center;
    display: flex;
    padding: 0 20px 0 30px;
    border-radius: 10px;
    background-color: white;
    border-bottom: 1px solid $neutral-200;
    height: 50px;
    justify-content: space-between;
    width: 57vw;
    margin-left: 10%;
    font-weight: 600;
    font-size: 14px;
}

.chat-window-static {
    padding-right: 375px;
}

.chat-control-rightsidebar {
    position: fixed;
    padding-top: 7px;
    top: 50px;
    right: 0;
    width: 375px;
    height: 100%;
    background-color: white;
    border-left: 1px solid $neutral-200;

    .p-tabview-panels {
        padding-right: 5px !important;
    }

    .p-tabview-panel {
        overflow-y: auto;
        height: calc(100vh - 120px);
    }

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #e1e2e5;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #bdbebf;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b6b6b7;
    }
}

.page-chat,
.chat,
.feedback {
    padding: 0;
}

.page-filters {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    gap: 10px;
    justify-content: end;
    flex-wrap: wrap;

    .filters__item {
        &:first-child {
            margin-right: auto;
        }
    }
}

.multiselector-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.multiselector-item {
    display: flex;
    align-items: center;
}

.multiselector {
    margin-right: 10px;
}

.bot-message-container {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
}

.chat-bot-logo img {
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 50%;
    object-fit: cover;
    background-color: $neutral-700;
    min-width: 30px;
}

// Countries
.country-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.country-name {
    margin-left: 10px;
}

.footer-template {
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 10px;
    border-top: 1px solid $neutral-200;
    border-radius: 0 0 10px 10px;
    font-weight: 600;
}

.grecaptcha-badge {
    visibility: hidden;
}

.access-denied-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: $neutral-100;

    .content {
        text-align: center;

        .title {
            color: $neutral-700;
            text-align: center;
            font-family: Roboto;
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .message {
            color: $neutral-600;
            text-align: center;
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 186%;
        }

        .input-field {
            margin-top: 20px;
        }

        .error {
            color: $destructive-500;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 5px;
            text-align: left;
            padding-left: 10px;
        }
    }
}

.button-container {
    margin-top: 8px;

    .button__item {
        display: inline-block;
        margin: 0 5px 5px 0;
        text-align: left;

        .btn-hover {
            &:hover {
                background-color: $primary !important;
                color: white !important;
            }
        }
    }
}

.grid-filters {
    display: flex;
    padding: 10px 0;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    &.flex-start {
        justify-content: flex-start;
    }

}


.classification-scheme {
    .p-tabview .p-tabview-panels {
        background: none !important;
        padding: 0 !important;
    }
}

.error-message {
    color: $destructive-900;
    margin-bottom: 5px
}

.change-password-description {
    margin-bottom: 5px
}

.error-bubble {
    background-color: $destructive-100;
    color: $destructive-700;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 0;
}

.user-profile-container,
.change-password-container {
    h2 {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}

.user-profile-save-btn {
    margin-left: 0.5rem;
}

.two-fa-btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
}


.custom-checkbox .p-checkbox-box {
    border: 1px solid $primary !important;
}

.custom-checkbox .p-checkbox-box .p-checkbox-icon {
    color: $primary !important;
}


.user-initial-table-container {
    display: flex;
}

.user-initial-table {
    position: relative;
    background: $ibex-100;
    color: $ibex-600;
    font-size: 16px;
    width: 36px;
    min-width: 36px;
    height: 36px;
    padding: 0 3px;
    font-weight: 500;
    text-align: center;

    line-height: 36px;
    border-radius: 100%;
    margin-right: 8px;

    &.initial--sky {
        background: $sky-100;
        color: $sky-600;
    }

    &.initial--green {
        background: $emerald-100;
        color: $emerald-600;
    }
}

.user-initial-name {
    line-height: 16px;
}

.user-initial-email {
    font-weight: 600;
    font-size: 11px;
}


.record-count {
    font-size: 12px;
    padding-bottom: 10px;
    color: #374151;
    //transition: all 0.3s ease;
    text-align: right;


    &.fixed {
        background: #f7f7f7;
        padding: 12px 20px 12px 0;
        position: fixed;
        top: 70px;
        width: 100%;
        z-index: 10;
        right: 0;
        border-bottom: 1px solid #E5E5E5;
    }
}


.response-rating {

    display: flex;
    gap: 10px;

    i {
        font-size: 14px;
        margin-right: 5px;
    }
}



// citetation


.citations-container {
    display: flex;
    // flex-direction: row;
    flex-flow: row;
    gap: 10px;
    justify-content: start;
    overflow-x: auto;
    width: 715px;
    padding: 10px 0;
    //max-width: 900px;
    list-style-type: none !important;
}

.citation-box {
    // width: 260.666px;
    // min-width: 260.666px;
    background: white;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid $neutral-100;
    // cursor: pointer;
    //   overflow-x: auto;
    position: relative;
    padding-bottom: 50px;

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &.highlight {
        background: $neutral-100;
    }

    &.selected {
        background: $neutral-100;
    }

    &:hover {
        //  background: $neutral-100;
    }
}

.citeation__inner {
    width: 205px;
}

.citation-source {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 5px;
    height: auto;
    width: auto;
    display: inline-block;

    background: $neutral-100;
    padding: 5px 10px;
    border-radius: 10px;

    &.highlight {
        background: $neutral-200;
    }

    &.selected {
        background-color: $ibex-500;
        color: $base-white;
    }
}

.citation-title {
    //    font-weight: 600;
    //  text-decoration: underline;
    color: $neutral-800;
    padding-bottom: 10px;
    font-family: 'poppinsmedium';
    line-height: 130%;
    margin-top: 8px;
}

.citations-toggle {
    display: flex;
    font-weight: 600;
    padding-top: 15px;
    cursor: pointer;

    p {
        color: $neutral-600;
        // font-weight: 600;
    }

    i {
        padding-top: 2px;
        padding-left: 10px;
    }
}

.citation-source-icons {
    padding-top: 10px;
    gap: 5px;
    display: flex;
    position: absolute;
    bottom: 10px;

}

.citation-download-link {
    background: transparent;
    padding: 10px;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid $neutral-200;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
        color: $neutral-500;
    }

    &:hover {
        background-color: $primary;

        i {
            color: white;
        }

    }
}


.inner-accordion {
    max-height: 250px;
    overflow-y: auto;
    margin: 10px 0;
}


.color-preview-container {
    height: 10px;
    min-width: 10px;
    border: 1px solid #f1f1f1;
    border-radius: 100%;
}