.file-drop-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 150px;
    border: 2px dashed $neutral-200;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    position: relative;
    cursor: pointer;

    &:hover {
        border-color: $primary;
    }

    &.dragging {
        border: 2px dashed $primary;
        background-color: $primary-50;
    }
}

.file-input {
    display: none;
}

.file-label {
    color: #333;
    font-size: 14px;
    // font-weight: bold;
    text-align: center;
    margin: 5px 0 10px;
    cursor: pointer;
}

.file-list {
    list-style: none;
    padding: 0;
    margin-top: 10px;
    max-height: 150px;
    overflow-y: auto;
}

.file-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.file-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f2f2f2;
    padding: 5px 10px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.file-remove-button {
    background-color: #cc0000;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}