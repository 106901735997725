.rcw-conversation-container {
    .rcw-header {
        background-color: white;
        //  color: #fff;
        display: -ms-flexbox;
        display: flex;
        padding: 9px 20px;
        justify-content: space-between;
        border-bottom: 1px solid #e5e7eb;
        align-items: center;
        // margin-left: 280px;
    }

    .rcw-title {
        font-size: 16px;
        color: #1f2937;
        font-weight: 500;
        margin: 0;
        padding: 0;
    }

    .rcw-close-button {
        display: none;
    }

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        margin-right: 10px;
        vertical-align: middle;
    }
}

.rcw-full-screen {
    .rcw-header {
        //border-radius: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        position: relative;
    }

    .rcw-title {
        //  padding: 0 0 15px;
    }

    .rcw-close-button {
        background-color: $primary;
        border: 0;
        display: block;
        position: absolute;
        right: 10px;
        top: 20px;
        width: 40px;
        display: none;
    }

    .rcw-close {
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width: 800px) {
    .rcw-conversation-container {
        .rcw-header {
            border-radius: 0;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            position: relative;
        }

        .rcw-title {
            padding: 0 0 15px;
        }

        .rcw-close-button {
            background-color: $primary;
            border: 0;
            display: block;
            position: absolute;
            right: 10px;
            top: 20px;
            width: 40px;
        }

        .rcw-close {
            width: 20px;
            height: 20px;
        }
    }
}

.rcw-message {
    margin: 10px;
    display: -ms-flexbox;
    display: flex;
    word-wrap: break-word;
}

.rcw-timestamp {
    font-size: 10px;
    margin-top: -15px;
    margin-left: 45px;
}

.rcw-client {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: auto;

    .rcw-message-text {
        background-color: $primary;
        color: white;
        padding: 10px 15px 10px 15px;
        height: fit-content;
        width: fit-content;

        margin-top: 10px;
        margin-left: 10px;
        margin-bottom: 10px;
        border: 1px solid $neutral-300;
        box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        max-width: 215px;
        text-align: left;
    }

    .rcw-timestamp {
        -ms-flex-item-align: end;
        align-self: flex-end;
        padding-top: 50px;
    }
}

.rcw-response {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;

    .rcw-message-text {
        // background-color: $primary-100;
        background: white;
        padding: 10px 15px 10px 15px;
        height: fit-content;
        width: fit-content;
        margin-top: 10px;
        margin-left: 0px;
        margin-bottom: 10px;
        border: 1px solid $neutral-300;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
        align-items: center;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        // max-width: 80%;

        font-family: 'Gilroy';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        // line-height: 20px;

        color: #1f2937;

        flex: none;
        order: 0;
        flex-grow: 0;
    }
}

.rcw-message-text {
    p {
        margin: 0;
    }

    img {
        width: 100%;
        object-fit: contain;
    }
}

.rcw-avatar {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
}

.rcw-snippet {
    background-color: #f4f7f9;
    border-radius: 10px;
    padding: 15px;
    max-width: 215px;
    text-align: left;
}

.rcw-snippet-title {
    margin: 0;
}

.rcw-snippet-details {
    border-left: 2px solid #35e65d;
    margin-top: 5px;
    padding-left: 10px;
}

.rcw-link {
    font-size: 12px;
    text-decoration: underline;
}

.rcw-messages-container {
    .loader {
        margin: 10px;
        display: none;
        position: static;
        width: auto;
        height: auto;

        &.active {
            display: -ms-flexbox;
            display: flex;
        }
    }

    .loader-container {
        background: white;
        padding: 10px 15px 10px 15px;
        height: fit-content;
        width: fit-content;
        margin-top: 10px;
        margin-left: 40px;
        margin-bottom: 10px;
        border: 1px solid #e5e7eb;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
        align-items: center;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        max-width: 80%;
    }

    .loader-dots {
        display: inline-block;
        height: 4px;
        width: 4px;
        border-radius: 50%;
        background: gray;
        margin-right: 4px;
        animation: typing 1.5s ease infinite alternate;

        &:first-child {
            animation-delay: 0.3s;
        }

        &:nth-child(2) {
            animation-delay: 0.6s;
        }

        &:nth-child(3) {
            animation-delay: 0.9s;
        }

        &:nth-child(4) {
            animation-delay: 1.2s;
        }

        &:nth-child(5) {
            animation-delay: 1.5s;
        }
    }

    @keyframes typing {

        0%,
        100% {
            transform: translateY(0);
        }

        25% {
            transform: translateY(-3px);
        }

        50% {
            transform: translateY(0);
        }

        75% {
            transform: translateY(-3px);
        }
    }
}

.rcw-messages-container {
    -webkit-overflow-scrolling: touch;

    height: calc(100% - 80px);
    margin-right: 5px;
    margin-top: 10px;
    overflow-y: auto;
    padding: 10px 30px;

    // background: #F8F7FA;
}

.rcw-full-screen .rcw-messages-container {
    height: 100%;
    max-height: none;
}

@media screen and (max-width: 800px) {
    .rcw-messages-container {
        height: 100%;
        max-height: none;
    }
}

.rcw-sender {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    // background-color: $neutral-100;
    //height: 45px;
    border-top: 1px solid #e5e7eb;
    padding: 10px;
    //  border-radius: 10px;
    background: white;
    margin-top: 10px;

    &.expand {
        height: 55px;
    }
}

.rcw-new-message,
.rcw-input {
    width: 100%;
    border: 0 !important;
    // background-color: #f1f7f9;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Gilroy';
    min-height: 30px;
    line-height: 21px;
    background: transparent;
    resize: none;
    white-space: pre-wrap;
    resize: none;
    height: 25px;

    &:hover {
        cursor: text;
    }

    &:focus {
        outline: none;
        cursor: text;
    }

    &.expand {
        height: 40px;
    }
}

.rcw-message-disable {
    //color: #f1f7f9;
    // pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
}

.rcw-send {
    border: 0;
    float: right;
    background: $primary;
    border-radius: 5px;
    width: 32px;
    height: 32px;
    padding: 5px;
    font-size: 16px;
    cursor: pointer;

    &:before {
        content: '\f1d8';
        font-family: 'Font Awesome 6 Pro';
        display: inline-block;
        color: white;
    }

    img {
        display: none;
    }

    // .rcw-send-icon {
    //     height: 25px;
    //     content: '\2708';
    // }
}

@media screen and (max-width: 800px) {
    .rcw-sender {
        border-radius: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }

    .quick-buttons-container {
        padding-bottom: 15px;
    }
}

// .quick-buttons-container {
//     background: #fff;
//     overflow-x: auto;
//     white-space: nowrap;
//     padding: 10px;

//     .quick-buttons {
//         list-style: none;
//         padding: 0;
//         margin: 0;
//         text-align: center;

//         .quick-list-button {
//             display: inline-block;
//             margin-right: 10px;
//         }
//     }
// }

@media screen and (max-width: 800px) {
    .quick-buttons-container {
        padding-bottom: 15px;
    }
}

.rcw-conversation-container {
    //  border-radius: 10px;
    // box-shadow: 0 2px 10px 1px #b5b5b5;
    box-shadow: none;
    // border: 1px solid $neutral-200;
    //  padding: 10px;
    // background: white;

    &.active {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.3s ease, transform 0.3s ease;
    }

    &.hidden {
        z-index: -1;
        pointer-events: none;
        opacity: 0;
        transform: translateY(10px);
        transition: opacity 0.3s ease, transform 0.3s ease;
    }
}

.rcw-full-screen .rcw-conversation-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    width: 100%;
    //    margin-left: 10%;
    // padding-right: 10%;
}

@media screen and (max-width: 800px) {
    .rcw-conversation-container {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;
    }
}

.rcw-launcher {
    .rcw-badge {
        position: fixed;
        top: -10px;
        right: -5px;
        background-color: red;
        color: #fff;
        width: 25px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        border-radius: 50%;
    }

    -webkit-animation-delay: 0;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-name: d;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: 0.5s;
    -moz-animation-name: d;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: 0.5s;
    animation-name: d;
    animation-fill-mode: forwards;
    -ms-flex-item-align: end;
    align-self: flex-end;
    background-color: $primary;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 2px 10px 1px #b5b5b5;
    height: 60px;
    margin-top: 10px;
    cursor: pointer;
    width: 60px;

    &:focus {
        outline: none;
    }
}

.rcw-open-launcher {
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-name: c;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: 0.5s;
    -moz-animation-name: c;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: 0.5s;
    animation-name: c;
    animation-fill-mode: forwards;
}

.rcw-close-launcher {
    width: 20px;
    -webkit-animation-delay: 0;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-name: b;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-delay: 0;
    -moz-animation-duration: 0.5s;
    -moz-animation-name: b;
    -moz-animation-fill-mode: forwards;
    animation-delay: 0;
    animation-duration: 0.5s;
    animation-name: b;
    animation-fill-mode: forwards;
}

@media screen and (max-width: 800px) {
    .rcw-launcher {
        bottom: 0;
        margin: 20px;
        position: fixed;
        right: 0;
    }

    .rcw-hide-sm {
        display: none;
    }
}

.rcw-previewer-container {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    overflow: hidden;
    position: fixed;
    z-index: 1200;
    left: 0;
    top: 0;

    .rcw-previewer-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        transition: all 0.3s ease;
    }

    .rcw-previewer-tools {
        position: fixed;
        right: 16px;
        bottom: 16px;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .rcw-previewer-button,
    .rcw-previewer-tools {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
    }

    .rcw-previewer-button {
        padding: 0;
        margin: 16px;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        outline: none;
        background-color: #fff;
        border: none;
    }

    .rcw-previewer-close-button {
        position: absolute;
        right: 0;
        top: 0;
    }

    .rcw-previewer-veil {
        width: 100%;
        height: 100%;
        overflow: scroll;
        position: relative;
    }
}

@keyframes b {
    0% {
        transform: rotate(-90deg);
    }

    to {
        transform: rotate(0);
    }
}

@keyframes c {
    0% {
        transform: rotate(90deg);
    }

    to {
        transform: rotate(0);
    }
}

@keyframes d {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.rcw-widget-container {
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 20px 20px 0;
    max-width: 370px;
    position: fixed;
    right: 0;
    width: 90vw;
    z-index: 9;
}

.rcw-full-screen {
    height: 100vh;
    margin: 0;
    max-width: none;
    width: 100%;
}

// @media screen and (max-width: 800px) {
//   .rcw-widget-container {
//     height: 100%;
//     height: 100vh;
//     margin: 0;
//     max-width: none;
//     width: 100%;
//   }
// }

.rcw-previewer .rcw-message-img {
    cursor: pointer;
}

.chatbot-header {
    position: relative;
    padding-left: 50px;

    .chatbot-img {
        position: absolute;
        left: 0;

        &::before {
            content: '';
            width: 8px;
            height: 8px;
            background: #35e65d;
            position: absolute;
            border-radius: 100%;
            right: -3px;
            bottom: 7px;
        }
    }

    span {
        display: block;
        font-size: 12px;
        font-weight: 300;
    }
}

.rcw-header {
    //  display: none !important;
}

.rcw-picker-btn {
    display: none;
}

.preview-chatbot-header {
    height: 50px;
    background-color: white;
    border-radius: 8px 8px 0 0;
    padding: 10px;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
}

.preview-chatbot-body {
    height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px $neutral-300;

    .rcw-header {
        display: none;
    }

    .rcw-conversation-container {
        padding: 0;
        border-radius: 0;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: $neutral-50;
    }

    .rcw-sender {
        border-radius: 0 0 5px 5px;
        box-shadow: 0px -2px 2px 0px rgba(0, 0, 0, 0.05);
        background: white;
    }
}

.bot-banner-fixed {
    background-repeat: repeat-y;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 0;
}

.chat-window-static {
    .rcw-content {
        height: calc(100vh - 175px);
        // padding-left: 280px;

        &.no--button {
            height: calc(100vh - 176px);
        }

        &.no--button-with-disclaimer {
            height: calc(100vh - 200px);
            //  background: red;
        }

        &.disclaimer-with-button {
            height: calc(100vh - 289px);
        }
    }
}

.chat-control-widget {
    .rcw-content {
        height: calc(100vh - 160px);
    }
}

.preview-chatbot-body {
    .rcw-content {
        height: calc(100% - 100px);
    }

    .rcw-sender {
        margin-top: 35px;
    }
}

.preview-chatbot-quick-buttons-container,
.quick-buttons-container {
    background: transparent;
    overflow-x: hidden;

    .quick-buttons {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .quick-list-button {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }
}

.preview-chatbot-quick-button,
.quick-button {
    background-color: transparent;
    color: $neutral-700;
    border: 1px solid $neutral-700;
    font-size: 12px;
    font-weight: 500;
    border-radius: 50px;
    padding: 5px 10px;
    cursor: pointer;
    outline: 0;

    &:active {
        background-color: $neutral-200;
        //  color: $primary-900;
    }

    &:hover {
        background-color: $primary;
        color: white;
        border: 1px solid $primary;
    }

    &.active {
        background-color: $primary;
        color: white;
        border: 1px solid $primary;
    }

    &.disabled {
        background-color: $neutral-100;
        cursor: not-allowed;
    }

    &.disabled:hover {
        background-color: $neutral-100;
        color: $primary;
        //  color: $neutral-400;
    }
}

.custom-quick-button {
    color: $primary;
    border: 1px solid $primary;
}

.rcw-disclaimer {
    font-size: 12px;
    color: $neutral-400;
    margin-top: 10px;
    padding: 0 5px;
    text-align: center;
}

.chat-query-button {
    background-color: transparent;
    color: $neutral-700;
    gap: 5px;
    border: 1px solid $neutral-700;
    font-size: 12px;
    font-weight: 500;
    border-radius: 50px;
    padding: 5px 10px;
    cursor: pointer;
    outline: 0;
}


.review-by {
    float: left;
    width: 800px;

    .review__by__inner {
        border: 1px dashed #d9d9d9;
        padding: 10px;
        border-radius: 4px;
        margin: 15px 0;
        position: relative;
        background: #fafafa;

        &:after {
            position: absolute;
            width: 15px;
            height: 15px;
            border-top: 0 dashed #D9D9D9;
            border-right: 0 dashed #D9D9D9;
            border-bottom: 1px dashed #D9D9D9;
            border-left: 1px dashed #D9D9D9;
            top: 0px;
            left: 18px;
            content: "";
            transform: rotate(135deg);
            margin-top: -7px;
            background: $neutral-25;
        }
    }

    h2 {
        color: $neutral-800;
    }

    .feedback {
        background: #e5e5e5;
        padding: 10px 14px;
        padding-left: 20px;
        border-radius: 4px;
        //  color: white;
        margin-top: 10px;
        line-height: 16px;
    }
}

.review-top {
    display: flex;
    flex-direction: column;
    gap: 7px;
}


.review-status {
    padding: 2px 10px 2px 10px;
    line-height: 20px;
    border-radius: 3px;
    color: $neutral-800;
    display: inline-block;
    font-weight: 500;
    background: $neutral-25;
    border: 1px solid $neutral-200;
    font-size: 12px;
    position: relative;

    &.status--success,
    &.active {
        background: $emerald-25;
        border: 1px solid $teal-200;
        color: $emerald-700;
    }

    &.status--deactivated,
    &.deactivated {
        background: $ibex-25;
        border: 1px solid $ibex-200;
        color: $ibex-700;
    }

    &.status--partial,
    &.parial {
        background: $sky-25;
        border: 1px solid $sky-200;
        color: $sky-700;
    }
}



.time-specification {
    padding: 20px;

    h2 {
        font-size: 16px;
    }

    .time-content {
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 10px 0;
        font-weight: 600;

        label {
            // background: $neutral-50;
            // border-radius: 20px;
            // padding: 4px 10px;
            margin-left: 10px;
        }

        i {
            //   font-size: 14px;
            margin-right: 5px;
        }
    }
}

// feedback detail

.chat-widget-wrapper {
    width: 100%;
    display: flex;
    border: 1px solid $neutral-200;
    border-radius: 5px;
    background: white;
    margin-top: 10px;
    // overflow: hidden;

    .rcw-full-screen .rcw-content {
        height: calc(100vh - 170px);
    }

    .rcw-sender {
        display: none;
    }

    .rcw-messages-container {
        // background-color: #111;
        height: 50vh;
        height: calc(100% - 25px);
        //    max-height: 410px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 10px 15px;
        margin-top: 10px;
        margin-right: 5px;
    }

    .rcw-widget-container {
        position: static;
        height: calc(100vh - 120px);
        width: 65%;
        background: $neutral-25;
        border-right: 1px solid $neutral-200;
        // border-radius: 12px;
    }

    .chat-control-rightsidebar {
        position: static;
        border: 0;
    }
}