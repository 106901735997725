/* Pre-defined color variables */

/* Color Styles  */
$base-white: #FFFFFF;
$base-black: #000000;
$base-gray-bg: #FAFAFA;
$neutral-25: #FAFAFA;
$neutral-50: #F5F5F5;
$neutral-100: #E5E5E5;
$neutral-200: #D4D4D4;
$neutral-300: #A3A3A3;
$neutral-400: #737373;
$neutral-500: #525252;
$neutral-600: #404040;
$neutral-700: #262626;
$neutral-800: #171717;
$neutral-900: #0A0A0A;
$white: #fff;
$destructive-50: #fef2f2;
$destructive-100: #fee2e2;
$destructive-200: #fecaca;
$destructive-300: #fca5a5;
$destructive-400: #f87171;
$destructive-500: #ef4444;
$destructive-600: #dc2626;
$destructive-700: #b91c1c;
$destructive-800: #991b1b;
$destructive-900: #7f1d1d;
$ibex-25: #FFEFF3;
$ibex-50: #FFE0E8;
$ibex-100: #FFC6D7;
$ibex-200: #FF97B6;
$ibex-300: #FF5D91;
$ibex-400: #FF2470;
$ibex-500: #FA0060;
$ibex-600: #D70052;
$ibex-700: #B4004E;
$ibex-800: #990249;
$ibex-900: #570022;
$fuchsia-25: #FDF4FF;
$fuchsia-50: #FAE8FF;
$fuchsia-100: #F5D0FE;
$fuchsia-200: #F0ABFC;
$fuchsia-300: #E879F9;
$fuchsia-400: #D946EF;
$fuchsia-500: #C026D3;
$fuchsia-600: #A21CAF;
$fuchsia-700: #86198F;
$fuchsia-800: #701A75;
$fuchsia-900: #4A044E;
$purple-25: #FAF5FFFF;
$purple-50: #F3E8FFFF;
$purple-100: #E9D5FFFF;
$purple-200: #D8B4FEFF;
$purple-300: #C084FCFF;
$purple-400: #A855F7FF;
$purple-500: #9333EAFF;
$purple-600: #7E22CEFF;
$purple-700: #6B21A8FF;
$purple-800: #581C87FF;
$purple-900: #3B0764FF;
$violet-25: #F5F3FFFF;
$violet-50: #EDE9FEFF;
$violet-100: #DDD6FEFF;
$violet-200: #C4B5FDFF;
$violet-300: #A78BFAFF;
$violet-400: #8B5CF6FF;
$violet-500: #7C3AEDFF;
$violet-600: #6D28D9FF;
$violet-700: #5B21B6FF;
$violet-800: #4C1D95FF;
$violet-900: #2E1065FF;
$indigo-25: #EEF2FFFF;
$indigo-50: #E0E7FFFF;
$indigo-100: #C7D2FEFF;
$indigo-200: #A5B4FCFF;
$indigo-300: #818CF8FF;
$indigo-400: #6366F1FF;
$indigo-500: #4F46E5FF;
$indigo-600: #4338CAFF;
$indigo-700: #3730A3FF;
$indigo-800: #312E81FF;
$indigo-900: #1E1B4BFF;
$blue-25: #EFF6FFFF;
$blue-50: #DBEAFEFF;
$blue-100: #BFDBFEFF;
$blue-200: #93C5FDFF;
$blue-300: #60A5FAFF;
$blue-400: #3B82F6FF;
$blue-500: #2563EBFF;
$blue-600: #1D4ED8FF;
$blue-700: #1E40AFFF;
$blue-800: #1E3A8AFF;
$blue-900: #172554FF;
$sky-25: #F0F9FFFF;
$sky-50: #E0F2FEFF;
$sky-100: #BAE6FDFF;
$sky-200: #7DD3FCFF;
$sky-300: #38BDF8FF;
$sky-400: #0EA5E9FF;
$sky-500: #0284C7FF;
$sky-600: #0369A1FF;
$sky-700: #075985FF;
$sky-800: #0C4A6EFF;
$sky-900: #082F49FF;
$cyan-25: #ECFEFFFF;
$cyan-50: #CFFAFEFF;
$cyan-100: #A5F3FCFF;
$cyan-200: #67E8F9FF;
$cyan-300: #22D3EEFF;
$cyan-400: #06B6D4FF;
$cyan-500: #0891B2FF;
$cyan-600: #0E7490FF;
$cyan-700: #155E75FF;
$cyan-800: #164E63FF;
$cyan-900: #083344FF;
$teal-25: #F0FDFAFF;
$teal-50: #CCFBF1FF;
$teal-100: #99F6E4FF;
$teal-200: #5EEAD4FF;
$teal-300: #2DD4BFFF;
$teal-400: #14B8A6FF;
$teal-500: #0D9488FF;
$teal-600: #0F766EFF;
$teal-700: #115E59FF;
$teal-800: #134E4AFF;
$teal-900: #042F2EFF;
$emerald-25: #F0FDF4FF;
$emerald-50: #DCFCE7FF;
$emerald-100: #BBF7D0FF;
$emerald-200: #86EFACFF;
$emerald-300: #4ADE80FF;
$emerald-400: #22C55EFF;
$emerald-500: #16A34AFF;
$emerald-600: #15803DFF;
$emerald-700: #166534FF;
$emerald-800: #14532DFF;
$emerald-900: #052E16FF;
$green-25: #ECFDF5FF;
$green-50: #D1FAE5FF;
$green-100: #A7F3D0FF;
$green-200: #6EE7B7FF;
$green-300: #34D399FF;
$green-400: #10B981FF;
$green-500: #059669FF;
$green-600: #047857FF;
$green-700: #065F46FF;
$green-800: #064E3BFF;
$green-900: #022C22FF;
$lime-25: #F7FEE7FF;
$lime-50: #ECFCCBFF;
$lime-100: #D9F99DFF;
$lime-200: #BEF264FF;
$lime-300: #A3E635FF;
$lime-400: #84CC16FF;
$lime-500: #65A30DFF;
$lime-600: #4D7C0FFF;
$lime-700: #3F6212FF;
$lime-800: #365314FF;
$lime-900: #1A2E05FF;
$yellow-25: #FEFCE8FF;
$yellow-50: #FEF9C3FF;
$yellow-100: #FEF08AFF;
$yellow-200: #FDE047FF;
$yellow-300: #FACC15FF;
$yellow-400: #EAB308FF;
$yellow-500: #CA8A04FF;
$yellow-600: #A16207FF;
$yellow-700: #854D0EFF;
$yellow-800: #713F12FF;
$yellow-900: #422006FF;
$amber-25: #FFFBEBFF;
$amber-50: #FEF3C7FF;
$amber-100: #FDE68AFF;
$amber-200: #FCD34DFF;
$amber-300: #FBBF24FF;
$amber-400: #F59E0BFF;
$amber-500: #D97706FF;
$amber-600: #B45309FF;
$amber-700: #92400EFF;
$amber-800: #78350FFF;
$amber-900: #451A03FF;
$orange-25: #FFF7EDFF;
$orange-50: #FFEDD5FF;
$orange-100: #FED7AAFF;
$orange-200: #FDBA74FF;
$orange-300: #FB923CFF;
$orange-400: #F97316FF;
$orange-500: #EA580CFF;
$orange-600: #C2410CFF;
$orange-700: #9A3412FF;
$orange-800: #7C2D12FF;
$orange-900: #431407FF;
$stone-25: #FAFAF9FF;
$stone-50: #F5F5F4FF;
$stone-100: #E7E5E4FF;
$stone-200: #D6D3D1FF;
$stone-300: #A8A29EFF;
$stone-400: #78716CFF;
$stone-500: #57534EFF;
$stone-600: #44403CFF;
$stone-700: #292524FF;
$stone-800: #1C1917FF;
$stone-900: #0C0A09FF;
$zinc-25: #FAFAFAFF;
$zinc-50: #F4F4F5FF;
$zinc-100: #E4E4E7FF;
$zinc-200: #D4D4D8FF;
$zinc-300: #A1A1AAFF;
$zinc-400: #71717AFF;
$zinc-500: #52525BFF;
$zinc-600: #3F3F46FF;
$zinc-700: #27272AFF;
$zinc-800: #18181BFF;
$zinc-900: #09090BFF;
$gray-25: #F9FAFBFF;
$gray-50: #F3F4F6FF;
$gray-100: #E5E7EBFF;
$gray-200: #D1D5DBFF;
$gray-300: #9CA3AFFF;
$gray-400: #6B7280FF;
$gray-500: #4B5563FF;
$gray-600: #374151FF;
$gray-700: #1F2937FF;
$gray-800: #111827FF;
$gray-900: #030712FF;
$slate-25: #F8FAFCFF;
$slate-50: #F1F5F9FF;
$slate-100: #E2E8F0FF;
$slate-200: #CBD5E1FF;
$slate-300: #94A3B8FF;
$slate-400: #64748BFF;
$slate-500: #475569FF;
$slate-600: #334155FF;
$slate-700: #1E293BFF;
$slate-800: #0F172AFF;
$slate-900: #020617FF;
$primary-50: #f4f4ff;
$primary-100: #e1e1fe;
$primary-200: #c3c4fe;
$primary-300: #9b9dfd;
$primary-400: #4b4efc;
$primary-500: #4b4efc;
$primary-600: #1e22fb;
$primary-700: #0408e7;
$primary-800: #0306ba;
$success-50: #f0fdf4;
$success-100: #dcfce7;
$success-200: #bbf7d0;
$success-300: #86efac;
$success-400: #4ade80;
$success-500: #22c55e;
$success-600: #16a34a;
$success-700: #15803d;
$success-800: #166534;
$success-900: #14532d;
$warning-50: #fffbeb;
$warning-100: #fef3c7;
$warning-200: #fde68a;
$warning-300: #fcd34d;
$warning-400: #fbbf24;
$warning-500: #f59e0b;
$warning-600: #d97706;
$warning-700: #b45309;
$warning-800: #92400e;
$warning-900: #78350f;


$primary: #4b4efc;

$secondary: #475467;

$clr-red: #dc3545;
$clr-green: #3bb001;
$clr-yellow: #ffc107;
$clr-amber: #fd7e14;

/* Pre-defined fonts variables */

$zindex: (
    header: 32,
    filter-fix: 20,
    sidebar: 31,
    modal: 35,
    modalcontent: 10,
    overlay: 100,
    overlaywidget: 13,
    dropdown: 18,
    bootstrap-dropdown: 19,
    filter: 10
);

$primary-font-bold: "Gilroy-bold", sans-serif;
$primary-font-semibold: "Gilroy-Semibold", sans-serif;
$primary-font-medium: "Gilroy-Medium", sans-serif;
$primary-font-regular: "Gilroy-Regular", sans-serif;
$primary-font-light: "Gilroy-Light", sans-serif;
$secondary-font-regular: "Axiforma-Regular", sans-serif;
$tertiary-font-regular: "Inter", sans-serif;
$icon-font: "Font Awesome 6 Pro";

// Interactions
$transition-time-base: 0.3s;
$sidebar-width: 260px;



$iphone4s: "iphone4s";
$iphone5s: "iphone5s";
$iphone6: "iphone6";
$iphone6plus: "iphone6plus";
$xs: 480px; // Default Mobile-first approach
$sm: 768px; // Respond Mixin's Default Tablet size
$md: 992px; // Medium Desktops and Laptops
$lg: 1200px; // Large Screens
$mobile_first_approach: true !default; // if set false, the min-width will change to max-width
$most_used_device: $iphone4s; // Values : iphone4s, iphone5s, iphone6, iphone plus