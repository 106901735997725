@import 'variables'; // variables declarations
@import 'mixins'; // useful mixin


@import "vendor/fontawesome/fontawesome";
@import "vendor/fontawesome/light";
@import "vendor/fontawesome/solid";
@import "vendor/fontawesome/sharp-solid";
@import "vendor/fontawesome/regular";
@import "vendor/fontawesome/duotone";
@import "vendor/fontawesome/duotone-icons";
@import "vendor/fontawesome/brands";
@import "typefaces";
@import "mixin-respondTo";
// Susy
@import "vendor/susy";
@import "vendor/breakpoint";

/** 
 * App Configuration
 */
@import "login";
@import "app-config";
@import "spacing-helper";
@import "file-upload";
@import "toggle";


// structure  
//@import "mixins"; 

.p-checkbox {
  &.p-highlight {
    .p-checkbox-box {
      border-color: $primary !important;
      background: $primary !important;
    }
  }
}


@import "reset";
@import "initials";


/* Helpers */
@import "helpers";
@import "sidebar";

@import "header"; // defines the whole layout
@import "forms";
@import "messages";
@import "grid";
@import "loader";
@import "browser-frame";

@import "layout";
@import "chat-bot";
@import "bot-studio";
@import "speech";


@import "vendor/prime-react/themes/saga/saga-blue/theme.scss";
@import "primeng-overwrites";

//@import "animate";

#root {
  height: 100%;
}