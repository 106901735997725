html {
    height: 100%;
    font-size: 12px; //Body font size is 14px
}

a {
    text-decoration: none;
    color: $primary;
}

ul {
    list-style: none;
}

// ::placeholder {
//     color: $light-grayish-blue;
//     font-family: $primary-font-regular;
// }

input,
select {
    // font-family: $primary-font-regular;
}

textarea {
    resize: none;
}

button {
    background: transparent;
}

:root {
    // font-size: 62.5%;

    body {
        font-family: 'Gilroy';
        //   font-size: 1.6rem; //Body font size is 16px
        /* background-color: $lighter-gray--secondary; */
        background-color: #F3F4F6;
        position: relative;
        height: 100%;
        font-weight: 500;

        // &.chat-layout {
        //     overflow: hidden;
        // }
    }
}

main {
    width: 100%;
    height: 100%;

}


/* Content Wrapper */

.content-wrapper {
    height: 100%;
    /* display: flex; */
}


.container {
    padding: 50px 0 0 60px;
}

.main {
    padding: 10px;
}




#root,
body,
html {
    height: 100%;
}

.widget-container {
    //  border: 1px solid #E5E7EB;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
}



.opt-field {
    display: flex;
    gap: 10px;
    align-items: center;
}




.error-pages {
    padding: 100px 20px 50px;
    text-align: center;

    i {
        font-size: 30px;
        color: $primary;
    }

    h1 {
        font-size: 20px;
        font-weight: 500;
        margin: 20px 0;
    }

    p {
        font-size: 16px;
        margin: 5px 0;
    }

}