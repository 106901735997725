.parent-container {
    display: flex;
}

/* Create bot container */

.create-bot-container {
    //padding: 10px 30px 50px 20px;
    padding: 10px 10px 50px 240px;
}

.create-bot-sidebar {
    left: 60px;
    padding: 0;
    top: 50px;
    position: fixed;
    height: 100%;
    z-index: 6;
    background: #fff;
    width: 230px;
    border-right: 1px solid $neutral-200;
    padding: 20px 15px;
    // display: none;
}

.create-bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px 5px 0;

    h2 {
        font-size: 11px;
        color: $neutral-800;
        // font-weight: 600;
        font-family: $primary-font-semibold;
        font-weight: 600;
    }

    i {
        font-size: 14px;
        color: $neutral-400;
    }
}

.bot-action {
    display: flex;
    justify-content: end;
    gap: 10px;
    padding-bottom: 20px;

    .back-btn {
        margin-right: auto;
    }
}

.source-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;

    &.source--setting {
        padding-bottom: 0;
    }

    p {
        font-weight: 500;
    }
}

/* Bot source container */
.white-container-box {
    float: left;
    width: 100%;
    border-radius: 5px;
    border: 1px solid $neutral-200;
    background: white;
    padding: 20px;
    margin-bottom: 10px;

    /* width */
    ::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #e1e2e5;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #bdbebf;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b6b6b7;
    }

    .box-container__inner {
        overflow-y: auto;
        max-height: 200px;
    }
}

.grid-text {
    font-weight: 500;
    color: $primary;
}

.container-bot {
    margin: 40px auto 0;
    padding-right: 10px;
    padding-left: 10px;
    max-width: 650px;
    height: 100%;
    @include clearfix();
}

.sources-list {
    display: flex;
    flex-direction: column;
}

.source-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    width: 100%;
    margin-bottom: 1px;
    border-radius: 5px;

    &:hover,
    &.active {
        background-color: $primary-100;

        .source-icon {
            color: $primary-600;
        }

        .source-name {
            color: $primary-600;
        }
    }

    .source-icon {
        color: $neutral-400;
        display: flex;
        text-align: center;
        margin-right: 10px;
        font-size: 18px;
        width: 24px;
        height: 24px;
        flex-direction: column;
        justify-content: center;
    }

    .source-name {
        color: $neutral-500;
        font-family: Gilroy;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .counter-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 17.25px;
        height: 17.25px;
        flex-shrink: 0;
        border-radius: 50%;
        margin-left: auto;
        background-color: $primary;
        color: #fff;

        &.no-source {
            background-color: #ccc;
            color: $neutral-600;
        }
    }
}

// Website source
.clear-btn {
    display: flex;
    width: 44px;
    height: 36px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;

    border-radius: 5px;
    border: 1px solid #d0d5dd;
    background: #fff;

    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: $neutral-500;
}

// .back-btn {
//     display: flex;
//     height: 32px;
//     padding: 10px 12px;
//     justify-content: center;
//     align-items: center;
//     gap: 8px;

//     border-radius: 5px;
//     border: 1px solid var(--gray-300, #d0d5dd);
//     background: var(--white, #fff);
//     color: $neutral-500;

//     /* Shadow/xs */
//     box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
// }

// .save-btn {
//     display: flex;
//     height: 32px;
//     padding: 10px 12px;
//     justify-content: center;
//     align-items: center;
//     gap: 8px;
//     border-radius: 5px;
//     background: $primary;
// }

// .add-btn-website {
//     display: flex;
//     width: 100%;
//     height: 36px;
//     padding: 10px 12px;
//     justify-content: center;
//     align-items: center;
//     gap: 8px;
//     flex-shrink: 0;

//     border-radius: 5px;
//     background: #4b4efc;
//     color: $neutral-50;
// }

// Document

.doc-info-container {
    display: flex;

    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.doc-info-label {
    color: $neutral-400;
    font-size: 12px;
    font-style: normal;
    margin-right: 5px;
    line-height: normal;
}

.tab-panel-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
}

.tab-panel-children {
    display: flex;
    align-items: center;
}

.tab-counter-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14.25px;
    height: 14.25px;
    flex-shrink: 0;
    border-radius: 50%;
    margin-left: 5px;
    background-color: $primary;
    color: #fff;
}

.p-button .p-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    min-height: 16px;
    font-size: 10px;
    line-height: 20px;
    flex-shrink: 0;
    border-radius: 25% !important;
    margin-left: 5px;
    background-color: $white;
    color: $primary;
    padding: 5px;
}