.p-overlaypanel {
  &.border-radius-5 {
    border-radius: 5px;
  }

  &.no--padding {
    .p-overlaypanel-content {
      padding: 0;
    }
  }
}


.field-checkbox {
  label {
    margin-left: 0.5rem;
    line-height: 1.5;
  }

}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {

  box-shadow: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none !important;
}

.p-icon-field-left>.p-inputtext {
  padding-left: 2.5rem;
}

.p-calendar-w-btn-right .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-calendar-w-btn-right .p-datepicker-trigger {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}